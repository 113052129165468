import React, { useEffect, useState } from "react";
import { GrFormNext } from "react-icons/gr";
import supabase from "../../../SupabaseConfig/SupabaseClient";
import { Link } from "react-router-dom";

export const Discover = () => {
  const [supabaseData, setSupabaseData] = useState([]);
  const [copyData, setCopyData] = useState([]);
  let customerData = [];
  let growthData = [];
  let insideData = [];

  const getData = async () => {
    try {
      const { data, error } = await supabase.from("BlogData").select("*");
      setSupabaseData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCopydata = async () => {
    try {
      const { data, error } = await supabase.from("BlogData_copy").select("*");
      setCopyData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    getCopydata();
  }, []);
  // console.log(supabaseData)

  let length = supabaseData.length - 3;

  for (let a = length; a > 0; a--) {
    if (customerData.length < 3) {
      customerData.push(supabaseData[a]);
    } else if (growthData.length < 3) {
      growthData.push(supabaseData[a]);
    } else if (insideData.length < 3) {
      insideData.push(supabaseData[a]);
    }
  }

  return (
    <div className="w-9/12 m-auto flex flex-col gap-6 my-12 tab:w-10/12 sm:w-11/12 sm:my-12">
      <div>
        <p className="text-center text-3xl font-bold my-4 sm:text-2xl">
          Discover the latest in...
        </p>
        <div>
          <div className="flex justify-between items-center">
            <p className="text-2xl font-bold my-6 sm:text-xl">
              Customer Service
            </p>

            <div className="flex justify-end sm:justify-start sm:">
              <button className="flex items-center text-lg font-medium gap-4 text-[#FFd32d] sm:gap-2">
                <a target="_blank" href="https://medium.com/@mr.gbjbuzz">
                  View More
                </a>
                <span>
                  <GrFormNext />
                </span>
              </button>
            </div>
          </div>
          <div className=" sm:w-full flex justify-between gap-12 tab:gap-3 sm:flex-col sm:gap-6">
            {customerData?.map((el, i) => (
              <a
                key={i}
                href={el.mediumBlogUrl}
                target="_main"
                className="w-2/5 sm:w-full">
                <div className="w-full flex flex-col gap-1 sm:w-full">
                  <img
                    className="aspect-[2]"
                    src={el.imageUrl}
                    alt={el.title}
                  />
                  <p className="text-sm">Customer Service</p>
                  <p className="text-lg font-medium tab:text-[15px] tab:leading-5 sm:text-sm">
                    {el.title}
                  </p>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>

      <div>
        <div className="flex justify-between items-center">
          <p className="text-2xl font-bold my-6 sm:text-xl ">
            Growth & Culture
          </p>
          <div className="flex justify-end sm:justify-start">
            <button className="flex items-center text-lg font-medium gap-4 text-[#ffd32d] sm:gap-2">
              <a target="_blank" href="https://medium.com/@mr.gbjbuzz">
                View More
              </a>
              <span>
                <GrFormNext />
              </span>
            </button>
          </div>
        </div>
        <div className=" sm:w-full flex justify-between gap-12 tab:gap-3 sm:flex-col sm:gap-6">
          {growthData?.map((el, i) => (
            <a
              key={i}
              href={el.mediumBlogUrl}
              target="_main"
              className="w-2/5 sm:w-full">
              <div className="w-full flex flex-col gap-1 sm:w-full">
                <img className="aspect-[2]" src={el.imageUrl} alt={el.title} />
                <p className="text-sm">Growth & Culture</p>
                <p className="text-lg font-medium tab:text-[15px] tab:leading-5 sm:text-sm">
                  {el.title}
                </p>
              </div>
            </a>
          ))}
        </div>
      </div>

      <div>
        <div className="flex justify-between items-center">
          <p className="text-2xl font-bold my-6 sm:text-xl">
            Inside Help Scout
          </p>
          <div className="flex justify-end sm:justify-start">
            <button className="flex items-center text-lg font-medium gap-4 text-[#FFd32d] sm:gap-2">
              <a target="_blank" href="https://medium.com/@mr.gbjbuzz">
                View More
              </a>
              <span>
                <GrFormNext color="red" />
              </span>
            </button>
          </div>
        </div>
        <div className=" sm:w-full flex justify-between gap-12 tab:gap-3 sm:flex-col sm:gap-6">
          {insideData?.map((el, i) => (
            <a
              key={i}
              href={el.mediumBlogUrl}
              target="_main"
              className="w-2/5 sm:w-full">
              <div className="w-full flex flex-col gap-1 sm:w-full">
                <img className="aspect-[2]" src={el.imageUrl} alt={el.title} />
                <p className="text-sm">Inside Help Scout</p>
                <p className="text-lg font-medium tab:text-[15px] tab:leading-5 sm:text-sm">
                  {el.title}
                </p>
              </div>
            </a>
          ))}
        </div>
        <h2 className="text-2xl font-semibold py-2 my-2 border-y-2 border-black">
          Blog
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {copyData.map((ele, index) => {
            // Check how many blogs are left in the last row
            const isLastRowSingle =
              copyData.length % 3 === 1 && index === copyData.length - 1;
            const isLastRowDouble =
              copyData.length % 3 === 2 &&
              (index === copyData.length - 2 || index === copyData.length - 1);

            return (
              <Link
                key={ele.id}
                to={`/blog/id=${ele.id}`}
                className={`block p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 
          dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 
          ${isLastRowSingle ? "lg:col-span-3 justify-self-center" : ""}
          ${isLastRowDouble ? "lg:col-span-2 justify-self-center" : ""}`}>
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {ele.title}
                </h5>
                <p className="font-normal text-gray-700 dark:text-gray-400">
                  {ele.introduction}
                </p>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};
