import React from "react";
import { LatestInsights } from "../Components/BlogPage/LatestInsights/LatestInsights";
import { RecentPost } from "../Components/BlogPage/RecentPost/RecentPost";
import { BestContent } from "../Components/BlogPage/BestContent/BestContent";
import { Discover } from "../Components/BlogPage/Discover/Discover";
import { ExploreSupport } from "../Components/BlogPage/ExploreSupport/ExploreSupport";
import { GetReady } from "../Components/GetReady/GetReady";
import ReactGA from 'react-ga4';
import { useEffect } from "react";
import { Helmet } from "react-helmet";
ReactGA.initialize(process.env.REACT_APP_GA_KEY);
export const Blog = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Blog Page" });
    window.scroll(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <meta name="keyword" content="Gbj buzz Blog,Gbj buzz reviews,Gbj buzz glassdoor"/>
        <title>Insights Hub - GBJ Buzz Blog</title>
        <meta name="description" content="Sign Up for insights, trends, and expertise in digital marketing, IT services, and more. Stay informed, stay ahead with our articles, guides, and updates that provide valuable knowledge for your digital success." />
      </Helmet>
      <div className="max-w-[1700px] m-auto">
        <LatestInsights />
        <RecentPost />
        <BestContent />
        <Discover />
        <ExploreSupport />
      </div>
      <GetReady />
    </>
  );
};
