import React, { useEffect, useRef, useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

import google from "../../../Assets/Logo/LandingPage/google-logo.svg";
import facebook from "../../../Assets/Logo/LandingPage/facebook-logo.svg";
import microsoft from "../../../Assets/Logo/LandingPage/Microsoft.svg";
import adobe from "../../../Assets/Logo/LandingPage/Adobe-logo.svg";
import canva from "../../../Assets/Logo/LandingPage/meta-canva.svg";
import salesforce from "../../../Assets/Logo/LandingPage/Salesforce.svg";
import GrowthGif from "../../../Assets/Logo/Gbjlogo.webp";
import GrowthGif_f0 from "../../../Assets/Logo/Gbjlogo.png";

const companyCard = [
  {
    img: google,
    alt: "google-logo",
    imgClass: "w-36 sm:w-[80px]  p-1 flex justify-center items-center",
    url: "https://www.google.com",
  },
  {
    img: facebook,
    alt: "facebook-logo",
    imgClass: "w-40 sm:w-24  p-1 flex justify-center items-center",
    url: "https://www.facebook.com",
  },
  {
    img: microsoft,
    alt: "microsoft-logo",
    imgClass: "w-40  sm:w-28  p-1 flex justify-center items-center",
    url: "https://www.microsoft.com",
  },
  {
    img: adobe,
    alt: "adobe-logo",
    imgClass: "w-40  sm:w-24  p-1 flex justify-center items-center",
    url: "https://www.adobe.com",
  },
  {
    img: salesforce,
    alt: "salesforce-logo",
    imgClass: "w-24 sm:w-24  p-1 flex justify-center items-center",
    url: "https://www.salesforce.com",
  },
  // {
  //   img: canva,
  //   alt: "canva-logo",
  //   imgClass:
  //     "hidden sm:block w-40 sm:w-24  p-1 flex justify-center items-center",
  //   url: "https://www.canva.com",
  // },
];

export const HeroSection = () => {
  const element = useRef();
  const navigate = useNavigate();
  const [trigger, setTrigger] = useState(false);
  const [width, setWidth] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const startTimer = () => {
    setIsActive(true);
  };

  const stopTimer = () => {
    setIsActive(false);
  };

  useEffect(() => {
    if (width === 68) {
      stopTimer();
    } else if (width === 0) {
      startTimer();
    }
    let interval;
    if (isActive) {
      interval = setInterval(() => {
        setWidth(prev => prev + 1);
      }, -10);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [width, isActive]);

  // console.log("WIDTH", width)

  return (
    <ScrollTrigger onEnter={() => setTrigger(true)}>
      <div className=" pt-12 pb-14 lg:pb-4 tab:py-10 sm:pb-0 sm:py-7 relative z-0 bg-[#F7F7F7]">
        <div className="max-w-[1600px] max-h-[600px] tab:h-auto sm:h-auto sm:max-h-none m-auto w-11/12">
          <div className=" flex justify-between items-start gap-x-4 sm:flex-col">
            <div className="relative w-1/2 flex flex-col justify-center gap-y-9  sm:w-full sm:mx-auto sm:gap-0">
              {/* <img src={whiteBg} alt="white-pattern-bg" className="absolute -top-32 -z-20 tab:-top-0 sm:-top-1/2" /> */}
              <div className="sm:w-11/12 sm:mx-auto flex flex-col justify-center gap-10 lg:gap-6 sm:gap-1">
                <div className="sm:hidden">
                  <h1>
                    <span
                      data-aos="fade-right"
                      data-aos-duration="1000"
                      data-aos-delay="100"
                      className="flex flex-col  text-[#181818] text-6xl xl:text-7xl 2xl:text-7xl  lg:text-5xl tab:text-4xl sm:text-[37px] font-semibold ">
                      Supercharge your
                    </span>
                    <span
                      data-aos="fade-right"
                      data-aos-duration="1000"
                      data-aos-delay="200"
                      className="flex flex-col  text-[#181818] text-6xl xl:text-7xl 2xl:text-7xl lg:text-5xl tab:text-4xl sm:text-[37px] font-semibold mt-2">
                      Business Growth
                    </span>
                  </h1>
                  {/* <h2 data-aos="fade-right"
                    data-aos-duration="1000" data-aos-delay="300" className="flex flex-col  text-[#181818] text-6xl xl:text-7xl 2xl:text-7xl lg:text-5xl tab:text-4xl sm:text-[37px] font-semibold mt-2">
                    forward-thinking
                  </h2> */}
                </div>
                <h2
                  data-aos="fade-right"
                  data-aos-duration="600"
                  className=" text-[#181818] font-semibold text-[47px] leading-[3rem] hidden sm:block">
                  Supercharge your Business Growth
                </h2>
                <h6
                  data-aos-duration="600"
                  data-aos-delay="200"
                  className="text-lg  tab:text-base sm:text-lg sm:mt-4 font-semibold sm:font-normal">
                  We're a top business development firm, experts in crafting
                  SaaS & PaaS solutions. Committed to understanding your unique
                  needs for successful growth.
                </h6>
              </div>
              <div className="sm:w-11/12 sm:mx-auto flex gap-x-8 items-center sm:flex-col sm:gap-y-5 sm:items-start ">
                {/* Button Container */}
                <div
                  data-aos-duration="600"
                  className="flex self-start shrink-0 justify-start  relative  w-[230px] h-[50px] sm:w-auto sm:top-1 sm:h-auto bg-black text-white rounded-full sm:bg-[#ffc900] sm:text-black overflow-hidden sm:mt-4 tab:w-[160px] tab:h-[35px] shadow-[0px_3px_3px] shadow-gray-300 hover:bg-[#ffc900] hover:text-black transition-all duration-300 ">
                  <a
                    href={"#contact-form"}
                    className={`  flex justify-around items-center  sm:text-[14px] font-semibold w-full h-full text-lg  sm:px-8 sm:py-2 py-1 px-5 bg-transparent z-10 rounded-lg tab:text-sm sm:gap-x-4`}>
                    Schedule Call <FaArrowRightLong />
                  </a>
                </div>
                <div data-aos-duration="1000">
                  <p className="underline decoration-2 underline-offset-4 text-[#323232] font-bold tab:text-sm sm:text-sm  sm:ms-3">
                    Let's Act Now
                  </p>
                </div>
                {/* Button Container Ended */}
              </div>
              <div className="flex flex-col gap-x-2 items-start sm:items-center ">
                <div className="flex gap-x-2 justify-between w-11/12 items-center sm:mt-6 sm:w-full sm:gap-x-2 sm:flex-wrap sm:justify-evenly">
                  {companyCard.map((ele, i) => (
                    <div key={i} className={ele.imgClass}>
                      <Link to={ele.url} target="_blank" key={i}>
                        <img
                          src={ele.img}
                          alt={ele.alt}
                          className="w-36 sm:w-32 sm:h-10 "
                          data-aos-duration={500 * (i + 1)}
                          data-aos-delay={50 * (i + 1)}></img>
                      </Link>
                    </div>
                  ))}
                </div>
                <div className=" w-max bg-[#ffc900]  mt-5 sm:mt-3 sm:text-center font-semibold self rounded-3xl transition-all duration-300 ease-linear hover:animate-pulse hover:bg-black hover:text-white">
                  <a
                    data-aos-duration="1000"
                    className="sm:px-10 px-3 py-1 tab:text-sm sm:text-sm hover:cursor"
                    href="#contact-form">
                    Partner Ecosystem at GBJ Buzz
                  </a>
                </div>
              </div>
            </div>
            <div className="w-6/12 sm:w-full sm:mx-auto flex justify-evenly item-center  sm:mt-5 relative xl:-bottom-8 lg:-bottom-0 tab:bottom-0 sm:bottom-0">
              <div className="grid grid-cols-2 justify-center items-end w-full">
                <div className="sm:hidden">
                  <img
                    loading="lazy"
                    src={GrowthGif}
                    alt="growth-gif"
                    data-aos-duration="1000"
                    className="relative w-11/12 -mb-4 sm:-mb-2 ms-auto max-h-[260px] max-w-[290px] tab:aspect-[4/5] lg:aspect-[3/5] xl:aspect-[3/5] 2xl:aspect-[3/5] sm:w-full sm:h-40 -z-20"
                  />
                </div>
                <div
                  data-aos="zoom-in-up"
                  data-aos-duration="1000"
                  className="flex flex-col gap-y-2 sm:gap-y-3 bg-[#F0F0F0] rounded-[40px]  pt-[42px] pb-[40px] px-[24px] tab:py-4 tab:rounded-3xl sm:py-4 sm:rounded-3xl sm:hidden">
                  <p className="text-6xl tab:text-5xl font-bold ">
                    {trigger && (
                      <CountUp start={1} end={106} duration={3} delay={0} />
                    )}
                    +
                  </p>
                  <p className="text-[#515151] text-lg tab:text-xs sm:text-[11px]">
                    Major clients, earning their profound trust and
                    collaboration.{" "}
                  </p>
                  <div className="relative mt-7 lg:mt-3 tab:mt-0 sm:mt-1 flex flex-row-reverse justify-between items-center">
                    <div className="h-1.5 bg-[#ffc900] w-[30%]" />
                    <div
                      className="h-1.5 bg-[#000]  top-0 transition-all ease-linear duration-100"
                      style={{ width: `${width}%` }}
                    />
                  </div>
                </div>
                <div
                  data-aos="zoom-in-up"
                  data-aos-duration="1000"
                  className="hidden sm:grid col-span-2 grid-cols-2  items-end bg-[#F0F0F0] rounded-2xl mb-2 pt-3">
                  <div className="max-w-[168px] ms-auto">
                    <img
                    loading="lazy"
                      src={GrowthGif_f0}
                      alt="growth-gif"
                      data-aos-duration="1000"
                      data-aos-offset="100"
                      className="relative w-9/12 -mb-4 sm:-mb-[2px] ms-auto sm:w-full sm:h-40 aspect-square "
                    />
                  </div>
                  <div className="flex flex-col gap-y-4 sm:gap-y-0 rounded-[40px]  pt-[51px] pb-[49px] pe-[24px] tab:py-4 tab:rounded-3xl sm:py-4 sm:rounded-3xl self-center max-w-[165px] me-auto">
                    <p className="text-[57px] font-bold ">
                      {trigger && (
                        <CountUp start={1} end={106} duration={4} delay={0} />
                      )}
                      +
                    </p>
                    <p className="text-[#515151] font-bold tab:text-xs sm:text-[13px] sm:leading-4 pb-2">
                      Major clients, earning their profound trust and
                      collaboration.{" "}
                    </p>
                  </div>
                  <div className="relative mt-7 bottom-3 tab:mt-0 sm:mt-1 w-full col-span-2 px-4 flex justify-between flex-row-reverse items-center">
                    <div className="h-1.5 bg-[#ffc900] w-[31%] self-end" />
                    <div className="h-1.5 bg-[#000]  top-0 w-8/12" />
                  </div>
                </div>
                {/* <div className="col-span-2 w-full ">
                  <img src={driveTraffic} alt="drive-Traffic" className=" sm:hidden z-[100]" />
                  <img src={driveTraffic_mobile_design} alt="drive-Traffic" className="hidden sm:block w-full" />

                </div> */}
                <div
                  data-aos-duration="1000"
                  className="col-span-2 w-full tab:h-40 sm:h-[8.5rem] relative  flex justify-between bg-black text-white pt-[3.3rem] xl:pt-12 lg:pt-10 pl-10 tab:pl-9 sm:pl-6 pr-7 sm:pr-3  rounded-3xl sm:rounded-2xl ">
                  <div
                    data-aos-duration="1000"
                    data-aos-delay="1000"
                    className="absolute top-10 tab:top-8 sm:top-6 left-10 tab:left-9 sm:left-6 flex gap-5 items-center">
                    <div className="w-14 lg:w-12 tab:w-8 sm:w-6 bg-[#A7A7A7] h-[1px]"></div>
                    <h4 className=" tab:text-[14px] sm:text-sm">
                      Elevate Sales, Skyrocket Your Growth
                    </h4>
                  </div>
                  <div className=" self-end mb-12 lg:mb-8 tab:mb-8 sm:mb-6 max-w-[20rem] sm:!max-w-[12rem]">
                    <h2
                      data-aos-duration="1000"
                      data-aos-delay="1000"
                      className="text-4xl xl:text-[30px] lg:text-[20px] tab:text-lg sm:text-[22px] font-semibold 2xl:leading-[3rem] sm:leading-7">
                      Drive more traffic and product sales
                    </h2>
                  </div>
                  <div className="flex gap-x-[12px] tab:gap-2 sm:gap-x-[6px] self-end ">
                    <div
                      data-aos="fade-right"
                      data-aos-duration="1000"
                      data-aos-delay="600"
                      className="w-20 xl:w-[70px] lg:w-16 tab:w-9 sm:w-8 h-20 xl:h-[4.5rem] lg:h-16 tab:h-12 sm:h-8 bg-[#FFDD63] self-end "
                    />
                    <div
                      data-aos="fade-right"
                      data-aos-duration="1000"
                      data-aos-delay="400"
                      className="w-20 xl:w-[70px] lg:w-16 tab:w-9 sm:w-8 h-32 xl:h-[7.5rem] lg:h-[6.8rem] tab:h-20 sm:h-12 bg-[#FFD540] self-end "
                    />
                    <div
                      data-aos="fade-right"
                      data-aos-duration="1000"
                      data-aos-delay="200"
                      className="w-20 xl:w-[70px] lg:w-16 tab:w-9 sm:w-8 h-[10.7rem] xl:h-40 lg:h-36 tab:h-24 sm:h-24 bg-[#FFC900] self-end "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
};
