// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'

const firebaseConfig = {
    apiKey: "AIzaSyARomearzId9wCgvNmROXlpqXeANZA_Qnw",
    authDomain: "gbjbuzz-website.firebaseapp.com",
    projectId: "gbjbuzz-website",
    storageBucket: "gbjbuzz-website.appspot.com",
    messagingSenderId: "585633381696",
    appId: "1:585633381696:web:b977f31fe36399f6468ea5",
    measurementId: "G-Z1YJ7C0FLQ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
