import { ToastContainer, toast } from "react-toastify";
import "./App.css";
import { AllRoutes } from "./AllRoutes/AllRoutes";
import React, { useEffect, useState } from "react";
import "aos/dist/aos.css";
import "aos/dist/aos.js";
import aos from "aos";
import { NewNavbar } from "./Components/Navbar/NewNavbar/NewNavbar";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import PopUpForm from "./Components/PopUpForm/PopUpForm";
import supabase from "./SupabaseConfig/SupabaseClient";
import NewFooter from "./Components/Footer/NewFooter";
import { getToken } from "firebase/messaging";

import { app, messaging } from "./firebase";
import Sanity from "./Components/Sanity";

ReactGA.initialize(process.env.REACT_APP_GA_KEY);
function App() {
  let location = useLocation();
  const locationDetail = location.pathname.split("/")[1];
  const [showScheduleCallButton, setShowScheduleCallButton] = useState(false);
  const [hide, setHide] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [clientToken, setClientToken] = useState(undefined)

  const [formData, setFormData] = useState({
    fullName: "",
    workEmail: "",
    phoneNumber: "",
  });

  const element = React.useRef();

  const firebaseMessaging = async () => {
    const permission = await Notification.requestPermission()

    if (permission === 'granted') {
      const token = await getToken(messaging, { vapidKey: "BGrl2ezrFHyeCdmL3Eh0Cv5Ru0U7Vn8E7BgLYGymxfFYQJgPo-978lwDgEecdNGEuEXl7Lj4G22O7tzG_A4-c3c" })

      if (token) {
        setClientToken(token)
        try {
          const { data, error } = await supabase
            .from('firebase_client_id')
            .select()
            .eq('client_id', token)

          console.log(error)
          if (data.length) {
            return false
          } else {
            const { err } = await supabase
              .from('firebase_client_id')
              .insert({ client_id: clientToken })
          }

        } catch (error) {
          console.log(error)
        }

      } else {
        console.log("token generation failed")
      }
    } else {
      console.log("permission denied")
    }
  }

  useEffect(() => {
    if (hide) {
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
    }
  }, [hide]);

  useEffect(() => {
    aos.init({
      duration: 1828,
      initClassName: "initial-anim",
      once: true,
      mirror: true,
      offset: 50,
      debounceDelay: 100,
      throttleDelay: 100,
    });
  }, []);
  useEffect(() => {
    firebaseMessaging()
  }, [clientToken])

  const handleSubmit = async e => {
    e.preventDefault();
    console.log({ ...formData });
    if (formData) {
      try {
        const { error } = await supabase
          .from("PopupContactFormData")
          .insert({ ...formData });
        if (error) {
          toast.error("Something went wrong");
        } else {
          toast.success("your request was successful");
          setHide(!hide);
        }
      } catch (error) {
        console.log(error);
        toast.error("Server error ");
      }
      setFormData({
        fullName: "",
        workEmail: "",
        phoneNumber: "",
      });
    }
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "/",
    });

    if (
      locationDetail === "" ||
      locationDetail === "/services" ||
      locationDetail === "about-us" ||
      locationDetail === "all-services" ||
      locationDetail === "contact" ||
      locationDetail === "blogs" ||
      locationDetail === "career"
    ) {
      setShowScheduleCallButton(true);
    }
  }, [location]);


  return (
    <div ref={element} className={`bg-transparent relative overflow-x-hidden`}>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className=" sticky top-0 h-auto z-[1000]">
        {!hide && <NewNavbar isOpen={isOpen} setIsOpen={setIsOpen} />}
      </div>
      <div className="" onClick={() => setIsOpen(false)}>
        <AllRoutes />
      </div>
      <NewFooter />
      {hide && (
        <PopUpForm
          hide={hide}
          setHide={setHide}
          formData={formData}
          setFormData={setFormData}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
}

export default App;
