import React from "react";
import { HeroSection } from "../Components/NewLandingPage/HeroSection/HeroSection";
import { Services } from "../Components/NewLandingPage/Services/Services";
import ProvideTheBest from "../Components/NewLandingPage/DiscoverExclusiveDeals/DiscoverExclusiveDeals";
import ContactOurSalesTeam from "../Components/NewLandingPage/ContactOurSalesTeam/ContactOurSalesTeam";
import { HappyClients } from "../Components/NewLandingPage/HappClients/HappyClients";

import DigitalMarketingFaq from "../Components/NewLandingPage/DigitalMarketingFaq/DigitalMarketingFaq";
import StrategicBusinessGrowth from "../Components/NewLandingPage/StrategicBusinessGrowth/StrategicBusinessGrowth";
import ReadWithUs from "../Components/NewLandingPage/ReadWithUs/ReadWithUs";
import Mission from "../Components/NewLandingPage/Mission/Mission";
import { Helmet } from "react-helmet";

const NewLandingPage = () => {
  return (
    <>
      <Helmet>
        <title>Supercharge your Business Growth - GBJ Buzz</title>
        <meta name="keyword" content="Gbj buzz careers,Gbj buzz reviews,Gbj buzz glassdoor"/>
        <meta
          name="description"
          content="GBJ Buzz provides services that boost sales and drive business growth. Our expertise includes marketing, AI solutions, software development and Lead generation"
        />
      </Helmet>
      <div className="font-['Lato'] z-[200]" id="blackCard">
        <HeroSection />
        <ProvideTheBest />
        <Services />
        <Mission />
        <HappyClients />
        <ContactOurSalesTeam />
        <DigitalMarketingFaq />
        <StrategicBusinessGrowth />
        <ReadWithUs />
      </div>
    </>
  );
};

export default NewLandingPage;
